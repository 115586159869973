import React from 'react';
import PropTypes from 'prop-types';
import LandingPageTemplate from '../../components/04_templates/PageLayouts/LandingPage';
import HomePageTemplate from '../../components/04_templates/PageLayouts/HomePage';
import Paragraphs from '../../components/02_molecules/Paragraphs';
import * as field from '../../utils/transforms.fields';
import '../../styles/styles.scss';

const LandingPage = ({ entity = null, blocks = [], layout = 'centered', pageType = '' }) => {
  const isHomePage = field.getEntityURL(entity).url === '/home';
  const Template = isHomePage ? HomePageTemplate : LandingPageTemplate;

  return (
    <Template>
      <Paragraphs blocks={blocks} layout={layout} pageType={pageType} />
    </Template>
  );
};

// Opt-out of Automatic Static Optimization.
// See https://nextjs.org/docs/api-reference/next.config.js/runtime-configuration
LandingPage.getInitialProps = async (args) => {
  return await Paragraphs.getInitialProps(args);
};

LandingPage.propTypes = {
  entity: PropTypes.shape({
    // TODO: Define entity object shape.
  }),
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      blockType: PropTypes.string.isRequired,
    }),
  ),
  // eslint-disable-next-line react/no-unused-prop-types
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      nextLink: PropTypes.shape({
        href: PropTypes.string,
        as: PropTypes.string,
      }),
    }),
  ),
  layout: PropTypes.string,
  pageType: PropTypes.string,
};

export default LandingPage;
