import React from 'react';

import styles from './index.module.scss';

const LandingPage = ({ children, ...props }) => (
  <div className={`page landing ${styles.landing}`} {...props}>
    {children}
  </div>
);

export default LandingPage;
